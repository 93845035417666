import {
  ConversationTypeEnum,
  Environment,
  GenerativeAIConversationItem,
  SystemOfRecord,
  generativeAIConversationItemType
} from "@coxauto-ui/communications-interfaces";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import { TextArea } from "@interstate/components/TextArea";
import { TextInputEventValue } from "@interstate/components/TextInput";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GenerativeAIPromptCard } from "./generative-ai-prompt-card";
import { GenerativeAIResponseCard } from "./generative-ai-response-card";
import { Button } from "@interstate/components/Button";
import { ArrowUturnLeftIcon } from "@interstate/components/Icons/ArrowUturnLeftIcon";
import { Alert } from "@interstate/components/Alert";

/* eslint-disable-next-line */
export interface GenerativeAIProps {
  customerId: string;
  customerSystem: SystemOfRecord;
  channelType: ConversationTypeEnum;
  env: Environment;
  initialPrompt?: string;
  isSuggestEdit: boolean;
  userDisplayName: string;
  insertGeneratedResponse: (message: string) => void;
  closeGenerativeAI: () => void;
}

const StyledGenerativeAI = styled.div`
  background-color: #f5f6f7;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;

  #generative-ai-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    #generative-ai-header-back-button {
      gap: 8px;
      align-items: baseline;
    }
  }

  #generative-ai-conversation-list {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  #generative-ai-hr {
    margin: 0;
  }

  #generative-ai-text-area {
    min-height: 100px;
    max-height: 200px;
  }

  #generative-ai-footer {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
`;

const generateReplyPlaceholder =
  "Add instructions here if desired and click regenerate to generate a new version";
const suggestEditPlaceholder =
  "Add text to be refined or modified for improved clarity and tone";

export function GenerativeAI({
  channelType,
  customerId,
  customerSystem,
  env,
  initialPrompt,
  isSuggestEdit,
  userDisplayName,
  insertGeneratedResponse,
  closeGenerativeAI
}: GenerativeAIProps) {
  const [prompt, setPrompt] = useState(initialPrompt || "");
  const [sessionId, setSessionId] = useState("");
  const [conversationItems, setConversationItems] = useState<
    GenerativeAIConversationItem[]
  >(
    isSuggestEdit
      ? []
      : [
          {
            prompt: "",
            responseMessages: [],
            type: generativeAIConversationItemType.response
          }
        ]
  );
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (scrollRef.current) {
      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      }, 300);
    }
  }, [conversationItems]);

  const onChange = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
    setPrompt(`${event.target?.value}` || "");
  };

  const setResponseMessages = (message: string, index: number) => {
    setConversationItems(prev => {
      return prev.map((x, i) => {
        if (i === index) {
          const responseMessages = [...x.responseMessages, message];

          return { ...x, responseMessages };
        }

        return x;
      });
    });
  };

  const onRegenerateClick = () => {
    setConversationItems(prev => {
      return [
        ...prev,
        {
          prompt,
          responseMessages: [],
          type: generativeAIConversationItemType.prompt
        },
        {
          prompt,
          responseMessages: [],
          type: generativeAIConversationItemType.response
        }
      ];
    });
    setPrompt("");
  };

  const onSuggestEditsClick = () => {
    setConversationItems(prev => {
      return [
        {
          prompt,
          responseMessages: [],
          type: generativeAIConversationItemType.response
        }
      ];
    });
    setPrompt("");
  };

  return (
    <StyledGenerativeAI>
      <div id="generative-ai-header">
        <h4 data-testid="generative-ai-header-title">Generative AI</h4>
        <Button
          buttonStyle="aux"
          data-testid="generative-ai-header-back-button"
          endIcon={
            <ArrowUturnLeftIcon data-testid="generative-ai-header-close-icon" />
          }
          id="generative-ai-header-back-button"
          onClick={closeGenerativeAI}
        >
          Back to inbox
        </Button>
      </div>
      <div id="generative-ai-conversation-list" ref={scrollRef}>
        {conversationItems.map(({ prompt, responseMessages, type }, index) => {
          const key = type + index;
          return type === generativeAIConversationItemType.prompt ? (
            <GenerativeAIPromptCard
              index={index}
              key={key}
              prompt={prompt}
              userDisplayName={userDisplayName}
            />
          ) : (
            <GenerativeAIResponseCard
              channelType={channelType}
              customerId={customerId}
              customerSystem={customerSystem}
              env={env}
              index={index}
              insertGeneratedResponse={insertGeneratedResponse}
              key={key}
              prompt={prompt}
              responseMessages={responseMessages}
              sessionId={sessionId}
              setResponseMessages={setResponseMessages}
              setSessionId={setSessionId}
            />
          );
        })}
      </div>
      <hr data-testid="generative-ai-hr" id="generative-ai-hr" />
      <TextArea
        data-testid="generative-ai-text-area"
        displayLabel={false}
        id="generative-ai-text-area"
        label=""
        name="generative-ai-text-area"
        placeholder={
          isSuggestEdit && conversationItems.length === 0
            ? suggestEditPlaceholder
            : generateReplyPlaceholder
        }
        value={prompt}
        onChange={onChange}
      />
      <Alert
        data-testid="generative-ai-verify-info-alert"
        id="generative-ai-verify-info-alert"
        role="alert"
        title="Please Verify Info"
        type="warning"
      >
        <span>
          AI can make mistakes. Check your communication carefully before
          sending
        </span>
      </Alert>
      <div id="generative-ai-footer">
        <Button
          buttonStyle="secondary"
          data-testid="generative-ai-cancel-button"
          id="generative-ai-cancel-button"
          onClick={closeGenerativeAI}
        >
          Cancel
        </Button>
        {isSuggestEdit && conversationItems.length === 0 ? (
          <Button
            buttonStyle="primary"
            data-testid="generative-ai-suggest-edits-button"
            id="generative-ai-suggest-edits-button"
            onClick={onSuggestEditsClick}
          >
            Suggest Edits
          </Button>
        ) : (
          <Button
            buttonStyle="primary"
            data-testid="generative-ai-regenerate-button"
            id="generative-ai-regenerate-button"
            onClick={onRegenerateClick}
          >
            {"Regenerate"}
          </Button>
        )}
      </div>
    </StyledGenerativeAI>
  );
}

export default GenerativeAI;
