import { Environment } from "@coxauto-ui/communications-interfaces";
import { getBaseUrl, getHeader } from "../config";
import {
  getCrmGenAIDealerRequest,
  getCrmGenAIDealerResponse
} from "./interfaces/get-crm-genai-dealer";
import { fetchWrapper } from "@coxauto-ui/core-http";

export async function getCrmGenAIDealer(
  { consumerId, consumerRecordSystem }: getCrmGenAIDealerRequest,
  env: Environment
) {
  const url = `${getBaseUrl(
    env
  )}cxm/crm-genai/dealer?consumerId=${consumerId}&consumerRecordSystem=${consumerRecordSystem}`;
  const headers = await getHeader();

  // if we experience infinite retries or the browser runs out of memory waiting for a prompt, setup an AbortController and pass it to the `signal` arg
  const response = await fetchWrapper.getAsync<getCrmGenAIDealerResponse>(
    url,
    headers
  );

  if (response.statusCode !== 200) {
    throw new Error(response.error?.message || "An unexpected error occurred");
  }

  return response.data;
}
