import { ConversationTypeEnum } from "@coxauto-ui/communications-interfaces";
// FloatingButton is available in newer Interstate Releases. Might be worth checking out.
import { Button } from "@interstate/components/Button";
import {
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  PhoneIcon
} from "@interstate/components/Icons";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface OpenReplyButtonProps {
  conversationType: ConversationTypeEnum;
  onOpenReplyButtonClick: () => void;
}

const StyledOpenReplyButton = styled.div`
  #customer-inbox-open-reply-button {
    border-radius: 9999px;
  }
`;

const content: Record<ConversationTypeEnum, JSX.Element | null> = {
  [ConversationTypeEnum.ALL]: null,
  [ConversationTypeEnum.CHAT]: null,
  [ConversationTypeEnum.EMAIL]: (
    <>
      <EnvelopeIcon />
      <span>Compose Email</span>
    </>
  ),
  [ConversationTypeEnum.NONE]: null,
  [ConversationTypeEnum.PHONE]: (
    <>
      <PhoneIcon />
      <span>Log a Call</span>
    </>
  ),
  [ConversationTypeEnum.TEXT]: (
    <>
      <ChatBubbleLeftIcon />
      <span>Text Customer</span>
    </>
  )
};

export function OpenReplyButton({
  conversationType,
  onOpenReplyButtonClick
}: OpenReplyButtonProps) {
  return (
    <StyledOpenReplyButton>
      <Button
        data-testid="customer-inbox-open-reply-button"
        htmlId="customer-inbox-open-reply-button"
        onClick={onOpenReplyButtonClick}
      >
        {content[conversationType]}
      </Button>
    </StyledOpenReplyButton>
  );
}

export default OpenReplyButton;
