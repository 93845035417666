import {
  DealSellingVehicle,
  DealStages,
  ListMode,
  Participant,
  TradeVehicle
} from "@coxauto-ui/ccxm/interfaces";
import { CcxmReusableLabelledInsightDisplay } from "@coxauto-ui/ccxm/reusable/labelled-insight-display";
import { trackInNewRelic } from "@coxauto-ui/core-third-party-newrelic";
import { Button } from "@interstate/components/Button";
import { LinkChip } from "@interstate/components/Chip";
import { MouseEvent, ReactNode, useCallback, useContext, useMemo } from "react";
import classnames from "classnames";
import { ImageWithFallback } from "@coxauto-ui/core/components";
import { CheckCircleIcon } from "@interstate/components/Icons/CheckCircleIcon";
import EmployeesInsightDisplay from "./employees-insight-display";
import { CustomerCardTabTypes } from "@coxauto-ui/util/derive-customer-view-link";
import { ArrowTopRightOnSquareIcon } from "@interstate/components/Icons/ArrowTopRightOnSquareIcon";
import { ListBulletIcon } from "@interstate/components/Icons/ListBulletIcon";
import { RoutingContext } from "@coxauto-ui/ccxm-routing";
import { ProgressChecker, Step } from "@coxauto-ui/progress-checker";
import {
  AllowedField,
  mapDealChecker
} from "@coxauto-ui/core/util/map-deal-checker";
import { DateDistanceToNow } from "@coxauto-ui/core-components";

/* eslint-disable-next-line */
export interface CardProps {
  consumerId: string;
  dealStages?: DealStages;
  consumerFirstName: string | null;
  consumerLastName: string | null;
  persona?: string;
  dealId: string;
  showNewStatusPill?: boolean;
  isNewDeal?: boolean;
  dealershipId: string;
  dealershipName: string;
  employees: Participant[];
  isAccepted: boolean;
  integratorLastUpdatedUtc: string;
  listMode: ListMode;
  manageDealButtonLink: string | null;
  mostRecentUpdateMessages: string[] | null;
  recordRow: number;
  sellingVehicle: DealSellingVehicle | null;
  showMultiDealButton?: boolean;
  tradeVehicles: TradeVehicle[];
  viewDealButtonLink: string | null;
  handleDealCardClick: (
    consumerId: string,
    dealId: string,
    recordRow: number,
    defaultCustomerTab?: CustomerCardTabTypes
  ) => void;
  handleGoogleAnalyticsEvent: (
    consumerId: string,
    eventName: string,
    recordRow: number
  ) => void;
  handleSelectMultiDeal: (consumerId: string, dealId: string) => void;
}

// NEED TO BRING IN THE ANALYTICS CHANGES FOR FILTER CATEGORIES

const htmlId = "close-my-deal-card";
const acceptedStatus = "accepted";

const mapStatusPill = {
  [acceptedStatus]: {
    color: "#c9eabf",
    id: "accepted",
    text: "Accepted"
  },
  sold: {
    color: "#c9eabf",
    id: "sold",
    text: "Sold"
  },
  lost: {
    color: "#fabaaf",
    id: "lost",
    text: "Lost"
  }
};

function isKeyOfMapStatusPill(key: string): key is keyof typeof mapStatusPill {
  return key in mapStatusPill;
}

export function Card({
  consumerId,
  consumerFirstName,
  consumerLastName,
  dealId,
  dealershipId,
  showNewStatusPill,
  dealershipName,
  employees,
  integratorLastUpdatedUtc,
  isAccepted,
  listMode,
  persona,
  manageDealButtonLink,
  mostRecentUpdateMessages,
  dealStages,
  recordRow,
  sellingVehicle,
  showMultiDealButton = false,
  tradeVehicles,
  viewDealButtonLink,
  handleDealCardClick,
  handleGoogleAnalyticsEvent,
  handleSelectMultiDeal,
  isNewDeal
}: CardProps) {
  const { selectedCustomerId, selectedMultiDealCustomerId, selectedDealId } =
    useContext(RoutingContext);

  const dealTracker: Step[] = useMemo(() => {
    if (!dealStages) return [];
    return mapDealChecker(dealStages, [AllowedField.status]);
  }, [dealStages, listMode]);

  const consumerFullName = useMemo(() => {
    return consumerFirstName || consumerLastName
      ? [consumerFirstName || "--", consumerLastName || "--"].join(" ")
      : "--";
  }, [consumerFirstName, consumerLastName]);

  const vehicleFullName = useMemo(() => {
    return sellingVehicle
      ? [
          sellingVehicle.year || "--",
          sellingVehicle.make || "--",
          sellingVehicle.model || "--",
          sellingVehicle.trim || "--"
        ].join(" ")
      : "--";
  }, [sellingVehicle]);

  const vehicleTypeAndStockNumber = useMemo(() => {
    return (
      <>
        <span data-testid={`${htmlId}-${recordRow}-vehicle-type`}>
          {sellingVehicle?.sellingVehicleType || "--"}
        </span>
        <span className="delimiter">|</span>
        <span data-testid={`${htmlId}-${recordRow}-stock-number`}>
          {sellingVehicle?.stockNumber
            ? `Stock #${sellingVehicle.stockNumber}`
            : "--"}
        </span>
      </>
    );
  }, [
    recordRow,
    sellingVehicle?.sellingVehicleType,
    sellingVehicle?.stockNumber
  ]);

  const mostRecentUpdateMessage = useMemo(() => {
    return (mostRecentUpdateMessages && mostRecentUpdateMessages[0]) || "--";
  }, [mostRecentUpdateMessages]);

  const borderMode = useMemo(() => {
    if (listMode === ListMode.desktopCustomer) {
      const isMultiDeal = selectedMultiDealCustomerId !== null;
      const isCurrentDeal = selectedDealId === dealId;
      const isCurrentCustomerAndDeal =
        selectedCustomerId === consumerId && isCurrentDeal;

      return (isMultiDeal ? isCurrentDeal : isCurrentCustomerAndDeal)
        ? "highlighted"
        : "standard";
    }
    return "standard";
  }, [
    listMode,
    selectedMultiDealCustomerId,
    selectedDealId,
    dealId,
    selectedCustomerId,
    consumerId
  ]);

  const onDealCardClick = () => {
    handleDealCardClick(consumerId, dealId, recordRow);
  };

  const onOpenDealPulseClick = (e: any) => {
    e.stopPropagation();
    handleDealCardClick(
      consumerId,
      dealId,
      recordRow,
      CustomerCardTabTypes.deal
    );
  };

  // interstate does not provide a type for this event
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMultiDealClick = (e: any) => {
    e.stopPropagation();
    handleSelectMultiDeal(consumerId, dealId);
  };

  const onOpenDealCentralClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      trackInNewRelic({
        componentAction: "DealCentralButtonClicked",
        locationLoadedFrom: document.title,
        dealerId: dealershipId,
        destinationUrl: viewDealButtonLink || ""
      });

      handleGoogleAnalyticsEvent(consumerId, "Deal Central Opened", recordRow);
    },
    [
      consumerId,
      dealershipId,
      handleGoogleAnalyticsEvent,
      recordRow,
      viewDealButtonLink
    ]
  );

  const onOpenShowroomClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      trackInNewRelic({
        componentAction: "ShowroomButtonClicked",
        locationLoadedFrom: document.title,
        dealerId: dealershipId,
        destinationUrl: viewDealButtonLink || ""
      });

      handleGoogleAnalyticsEvent(consumerId, "Showroom Opened", recordRow);
    },
    [
      consumerId,
      dealershipId,
      handleGoogleAnalyticsEvent,
      recordRow,
      viewDealButtonLink
    ]
  );

  const headerContent = useMemo(() => {
    const content: {
      className?: string;
      id: string;
      "data-bordermode"?: string;
      "data-consumerid"?: string;
      "data-consumername"?: string;
      "data-testid": string;
      value: ReactNode;
    }[] = [
      {
        className: "consumer-name",
        "data-bordermode": `consumer-${recordRow}-${borderMode}`,
        "data-consumerid": consumerId,
        "data-consumername": "consumer-insight-record-full-name",
        "data-testid": `consumer-${recordRow}-full-name`,
        id: `${htmlId}-${dealId}-consumer-name`,
        value: consumerFullName
      },
      {
        className: "dealership-name",
        "data-testid": `${htmlId}-${recordRow}-dealership-name`,
        id: `${htmlId}-${dealId}-dealership-name`,
        value: dealershipName
      }
    ];

    if (tradeVehicles.length > 0) {
      content.push({
        "data-testid": `${htmlId}-${recordRow}-trade-in`,
        id: `${htmlId}-${dealId}-trade-in`,
        value: (
          <span className="has-trade-in">
            <CheckCircleIcon color="sem.color.on-surface.success.muted" />
            <span>Trade-in</span>
          </span>
        )
      });
    }

    return content;
  }, [
    borderMode,
    consumerFullName,
    consumerId,
    dealId,
    dealershipName,
    recordRow,
    tradeVehicles.length
  ]);

  const getLastUpdatedUtcValue = useMemo(() => {
    if (persona)
      return (
        <span>
          <span>{persona}</span> (
          <DateDistanceToNow date={integratorLastUpdatedUtc} />)
        </span>
      );
    return <DateDistanceToNow date={integratorLastUpdatedUtc} />;
  }, [integratorLastUpdatedUtc, persona]);

  const statusPill = useMemo(() => {
    if (!showNewStatusPill && isAccepted) {
      return mapStatusPill[acceptedStatus];
    }
    const dealStageStatus = dealStages?.status;
    const allowedStatus = Object.keys(mapStatusPill);
    const isNotDesktop = listMode !== ListMode.desktopList;
    if (
      showNewStatusPill &&
      dealStageStatus &&
      isNotDesktop &&
      (allowedStatus.includes(dealStageStatus) || isAccepted)
    ) {
      if (isKeyOfMapStatusPill(dealStageStatus)) {
        return mapStatusPill[dealStageStatus];
      }
      return isAccepted ? mapStatusPill[acceptedStatus] : null;
    }
    return null;
  }, [listMode, dealStages?.status, showNewStatusPill]);

  return (
    <div
      className={classnames(borderMode, listMode, "close-my-deals-card", {
        "new-deal": !!isNewDeal,
        "progress-checker": !!dealStages
      })}
      data-testid={`${htmlId}-${recordRow}`}
      id={`${htmlId}-${dealId}`}
      onClick={onDealCardClick}
    >
      <div
        className="vehicle-image"
        data-testid={`${htmlId}-${recordRow}-vehicle-image`}
        id={`${htmlId}-${dealId}-vehicle-image`}
      >
        <ImageWithFallback
          alt="selling vehicle associated with the deal"
          data-testid={`${htmlId}-${dealId}-vehicle-img`}
          id={`${htmlId}-${dealId}-vehicle-img`}
          src={sellingVehicle?.vehicleImageUrl || ""}
        />
      </div>
      <CcxmReusableLabelledInsightDisplay
        appendedContent={
          (showMultiDealButton || statusPill) && (
            <>
              {showMultiDealButton && (
                <LinkChip
                  className="multi-deal-button"
                  data-testid={`${htmlId}-${recordRow}-multi-deal-button`}
                  id={`${htmlId}-${dealId}-multi-deal-button`}
                  label="+ Show All Deals"
                  onClick={onMultiDealClick}
                />
              )}
              {statusPill && (
                <span
                  className="status-pill"
                  data-testid={`${htmlId}-${recordRow}-${statusPill.id}`}
                  id={`${htmlId}-${dealId}-${statusPill.id}`}
                  style={{ backgroundColor: statusPill.color }}
                >
                  {statusPill.text}
                </span>
              )}
            </>
          )
        }
        className="card-header"
        content={headerContent}
        contentDelimiter={"|"}
        data-testid={`${htmlId}-${recordRow}-header`}
        htmlId={`${htmlId}-${dealId}-header`}
        multiline={
          listMode === ListMode.desktopCustomer ||
          listMode === ListMode.mobileList
        }
      />
      <CcxmReusableLabelledInsightDisplay
        className="vehicle-description"
        content={[
          {
            className: "shrink",
            "data-testid": `${htmlId}-${recordRow}-vehicle-description`,
            id: `${htmlId}-${dealId}-vehicle-description`,
            value: vehicleFullName
          },
          {
            className: "static",
            "data-testid": `${htmlId}-${recordRow}-vehicle-type-and-stock-number`,
            id: `${htmlId}-${dealId}-vehicle-type-and-stock-number`,
            value: vehicleTypeAndStockNumber
          }
        ]}
        contentDelimiter={"|"}
        data-testid={`${htmlId}-${recordRow}-vehicle`}
        displayLabel={
          listMode === ListMode.desktopList || listMode === ListMode.tabletList
        }
        htmlId={`${htmlId}-${dealId}-vehicle`}
        label="Vehicle"
        multiline={
          listMode === ListMode.desktopList || listMode === ListMode.tabletList
        }
      />
      {(listMode === ListMode.desktopList ||
        listMode === ListMode.tabletList) && (
        <EmployeesInsightDisplay
          dealId={dealId}
          employees={employees}
          htmlId={htmlId}
          recordRow={recordRow}
        />
      )}
      <CcxmReusableLabelledInsightDisplay
        className="recent-update"
        content={[
          {
            "data-testid": `${htmlId}-${recordRow}-recent-update-message`,
            id: `${htmlId}-${dealId}-recent-update-message`,
            value: mostRecentUpdateMessage
          },
          {
            "data-testid": `${htmlId}-${recordRow}-last-updated-utc`,
            id: `${htmlId}-${dealId}-last-updated-utc`,
            value: getLastUpdatedUtcValue
          }
        ]}
        data-testid={`${htmlId}-${recordRow}-recent-update`}
        displayLabel={
          listMode === ListMode.desktopList || listMode === ListMode.tabletList
        }
        htmlId={`${htmlId}-${dealId}-card-header`}
        label="Recent Update"
        multiline={
          listMode === ListMode.desktopList || listMode === ListMode.tabletList
        }
      />
      {dealStages && listMode === ListMode.desktopList && (
        <div className="checks" data-testid={`${htmlId}-${recordRow}-checks`}>
          <ProgressChecker
            className="checks-progress"
            data-testid="checks"
            id={`${htmlId}-${recordRow}-progress-checker`}
            steps={dealTracker}
          />
        </div>
      )}
      <div
        className={classnames(
          "actions",
          listMode === ListMode.desktopList ? "right" : "left"
        )}
        data-testid={`${htmlId}-${recordRow}-action-section`}
      >
        {manageDealButtonLink && (
          <>
            <a
              data-testid={`${htmlId}-${recordRow}-manager-view-anchor-tag`}
              href={manageDealButtonLink}
              rel="noreferrer"
              target="_blank"
            >
              <Button
                buttonStyle="tertiary"
                data-testid={`${htmlId}-${recordRow}-manager-view-button`}
                htmlId={`${htmlId}-${dealId}-manager-view-button`}
                size="small"
                startIcon={
                  listMode !== ListMode.mobileList ? (
                    <ArrowTopRightOnSquareIcon
                      data-testid={`${htmlId}-${recordRow}-manager-view-icon`}
                    />
                  ) : null
                }
                onClick={onOpenDealCentralClick}
              >
                Manager View
              </Button>
            </a>
            <span className="delimiter">|</span>
          </>
        )}
        {viewDealButtonLink && (
          <>
            <a
              data-testid={`${htmlId}-${recordRow}-sales-view-anchor-tag`}
              href={viewDealButtonLink}
              rel="noreferrer"
              target="_blank"
            >
              <Button
                buttonStyle="tertiary"
                data-testid={`${htmlId}-${recordRow}-sales-view-button`}
                htmlId={`${htmlId}-${dealId}-sales-view-button`}
                size="small"
                startIcon={
                  listMode !== ListMode.mobileList ? (
                    <ArrowTopRightOnSquareIcon
                      data-testid={`${htmlId}-${recordRow}-sales-view-icon`}
                    />
                  ) : null
                }
                onClick={onOpenShowroomClick}
              >
                Sales View
              </Button>
            </a>
            <span className="delimiter">|</span>
          </>
        )}
        <Button
          buttonStyle="tertiary"
          data-testid={`${htmlId}-${recordRow}-deal-summary-button`}
          htmlId={`${htmlId}-${recordRow}-deal-summary-button`}
          size="small"
          startIcon={
            listMode !== ListMode.mobileList ? (
              <ListBulletIcon
                data-testid={`${htmlId}-${recordRow}-deal-summary-icon`}
              />
            ) : null
          }
          onClick={onOpenDealPulseClick}
        >
          Deal Pulse
        </Button>
      </div>
    </div>
  );
}

export default Card;
