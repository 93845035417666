import styled from "styled-components";
import { Button } from "@interstate/components/Button";
import { useEffect, useRef, useState } from "react";
import {
  PostCrmGenAIContentStreamRequestPayload,
  postCrmGenAIContentStream
} from "@coxauto-ui/communications-api";
import {
  ConversationTypeEnum,
  Environment,
  SystemOfRecord
} from "@coxauto-ui/communications-interfaces";
import { EventSourceMessage } from "@microsoft/fetch-event-source";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";

/* eslint-disable-next-line */
export interface GenerativeAIResponseCardProps {
  channelType: ConversationTypeEnum;
  customerId: string;
  customerSystem: SystemOfRecord;
  env: Environment;
  index: number;
  prompt: string;
  responseMessages: string[];
  sessionId: string;
  insertGeneratedResponse: (response: string) => void;
  setSessionId: (sessionId: string) => void;
  setResponseMessages: (message: string, index: number) => void;
}

const StyledGenerativeAIResponseCard = styled.div`
  padding: 16px;
  background-color: #fff;
  border: 1px solid #cacfd9;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .generative-ai-response-card-insert-button {
    flex: 0 0 auto;
    width: min-content;
    margin-left: auto;
  }

  .generative-ai-response-card-prompt {
    font-style: italic;
  }
`;

const userFriendlyErrorMessage =
  "Unfortunately, there was an issue with the last prompt. Please click here to try again. If the problem continues, please contact your support team.";

export function GenerativeAIResponseCard({
  channelType,
  customerId,
  customerSystem,
  env,
  index,
  prompt,
  responseMessages,
  sessionId,
  setResponseMessages,
  setSessionId,
  insertGeneratedResponse
}: GenerativeAIResponseCardProps) {
  const [error, setError] = useState("");
  const [isInProgress, setIsInProgress] = useState(false);
  const streamStarted = useRef(false);

  useEffect(() => {
    if (streamStarted.current) {
      return;
    }

    streamStarted.current = true;

    (async function () {
      const payload: PostCrmGenAIContentStreamRequestPayload = {
        consumerRecordSystem: customerSystem,
        consumerId: customerId,
        channelType,
        input: prompt
      };

      if (sessionId) {
        payload.sessionId = sessionId;
      }

      const onClose = () => {
        setIsInProgress(false);
      };

      const onError = (error: any) => {
        throw error;
      };

      const onMessage = (ev: EventSourceMessage) => {
        if (ev.event === "Content") {
          setResponseMessages(ev.data.replace(/\\n/g, "\n\r"), index);
        } else if (ev.event === "SessionId") {
          setSessionId(ev.data);
        }
      };

      try {
        setIsInProgress(true);
        await postCrmGenAIContentStream({
          payload,
          env,
          onclose: onClose,
          onerror: onError,
          onmessage: onMessage
        });
      } catch (error: any) {
        setIsInProgress(false);
        setError(error?.message || "Sorry, something went wrong");
      }
    })();
    // not sure about the dep list... might remove it
  }, [
    channelType,
    customerId,
    customerSystem,
    env,
    index,
    prompt,
    sessionId,
    setResponseMessages,
    setSessionId
  ]);

  const onInsertGeneratedResponseClick = () => {
    insertGeneratedResponse(responseMessages.join(""));
  };

  return (
    <StyledGenerativeAIResponseCard>
      {prompt && index === 0 && (
        <div
          className="generative-ai-response-card-prompt"
          data-testid={`generative-ai-response-card-prompt-${index}`}
          id={`generative-ai-response-card-prompt-${index}`}
        >
          <div>Original Text:</div>
          <div>{prompt}</div>
        </div>
      )}
      {error && (
        <div
          className="generative-ai-response-card-error-wrapper"
          data-rawerrormessage={error}
          data-testid={`generative-ai-response-card-error-wrapper-${index}`}
          id={`generative-ai-response-card-error-wrapper-${index}`}
        >
          {userFriendlyErrorMessage}
        </div>
      )}
      {responseMessages.length === 0 && isInProgress && (
        <LoadingIndicator
          data-testid={`generative-ai-waiting-for-response-loading-indicator-${index}`}
          id={`generative-ai-waiting-for-response-loading-indicator-${index}`}
        />
      )}
      {responseMessages && (
        <div
          className="generative-ai-response-card-generated-response"
          data-testid={`generative-ai-response-card-generated-response-${index}`}
          id={`generative-ai-response-card-generated-response-${index}`}
        >
          {responseMessages.join("")}
        </div>
      )}
      <Button
        className="generative-ai-response-card-insert-button"
        data-testid={`generative-ai-response-card-insert-button-${index}`}
        disabled={isInProgress || responseMessages.length === 0}
        id={`generative-ai-response-card-insert-button-${index}`}
        onClick={onInsertGeneratedResponseClick}
      >
        Insert Text
      </Button>
    </StyledGenerativeAIResponseCard>
  );
}
