import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserProfile } from "@coxauto-ui/ccxm/api";
import {
  UserProfileResponse,
  UserProfileState
} from "@coxauto-ui/ccxm/interfaces";
import { getViewAsStatus } from "@coxauto-ui/ccxm/util/get-view-as";
export const USER_PROFILE_FEATURE_KEY = "userProfile";

const sessionStorageKey = "cxm-user-dealer-context";

export const fetchUserProfile = createAsyncThunk(
  "userProfile/fetchStatus",
  async (viewAsUsername: string) => {
    return await getUserProfile(viewAsUsername);
  }
);

const getInitialState = (): UserProfileState => {
  const initialState: UserProfileState = {
    bridgeUserId: "",
    dealerGroupId: "",
    bridgeUserName: "",
    dealerGroupName: "",
    loadingStatus: "not loaded",
    isEmployee: false,
    vinUserId: 0,
    viewAsUsername: "",
    error: null,
    messages: [],
    products: {}
  };
  const sessionStorageJSON = sessionStorage.getItem(sessionStorageKey);
  if (sessionStorageJSON) {
    const {
      dealerGroupId,
      dealerGroupName,
      bridgeUserId,
      bridgeUserName,
      isEmployee,
      vinUserId,
      products
    } = JSON.parse(sessionStorageJSON);
    initialState.bridgeUserId = bridgeUserId || "";
    initialState.bridgeUserName = bridgeUserName || "";
    initialState.dealerGroupId = dealerGroupId || "";
    initialState.dealerGroupName = dealerGroupName || "";
    initialState.isEmployee = isEmployee || false;
    initialState.vinUserId = vinUserId || 0;
    initialState.viewAsUsername = getViewAsStatus() || "";
    initialState.loadingStatus = "loaded";
    initialState.products = products;
  }
  return initialState;
};

export const initialUserProfileState: UserProfileState = getInitialState();

export const userProfileSlice = createSlice({
  name: USER_PROFILE_FEATURE_KEY,
  initialState: initialUserProfileState,
  reducers: {
    set: (state, action) => {
      const userProfile = { ...state, ...action.payload };

      return userProfile;
    },
    clearError: (state: UserProfileState) => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUserProfile.pending, (state: UserProfileState) => {
        state.loadingStatus = "loading";
      })
      .addCase(
        fetchUserProfile.fulfilled,
        (
          state: UserProfileState,
          action: PayloadAction<UserProfileResponse>
        ) => {
          sessionStorage.setItem(
            sessionStorageKey,
            JSON.stringify(action.payload.userProfile)
          );

          state.loadingStatus = "loaded";
          state.error = null;
          state.bridgeUserId = action.payload.userProfile.bridgeUserId || "";
          state.bridgeUserName =
            action.payload.userProfile.bridgeUserName || "";
          state.dealerGroupId = action.payload.userProfile.dealerGroupId || "";
          state.dealerGroupName =
            action.payload.userProfile.dealerGroupName || "";
          state.isEmployee = action.payload.userProfile.isEmployee || false;
          state.vinUserId = action.payload.userProfile.vinUserId || 0;
          state.viewAsUsername = getViewAsStatus() || "";
          state.messages = action.payload.userProfile.messages || [];
          state.products = action.payload.userProfile.products || {};
        }
      )
      .addCase(fetchUserProfile.rejected, (state: UserProfileState, action) => {
        state.loadingStatus = "error";
        state.error = action.error.message || null;
      });
  }
});

export const userProfileReducer = userProfileSlice.reducer;

export const userProfileActions = userProfileSlice.actions;

export const getUserProfileState = (rootState: any): UserProfileState =>
  rootState[USER_PROFILE_FEATURE_KEY];
