import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CcxmReusableBridgeBarFriendlySlidingDrawer } from "@coxauto-ui/ccxm/reusable/bridge-bar-friendly-sliding-drawer";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import { Button } from "@interstate/components/Button";
import { LoadNewDealModule } from "@coxauto-ui/core/new-deal-module";

import { getUserProfileState } from "@coxauto-ui/ccxm/store";
import { AssociatedDealer } from "@coxauto-ui/ccxm/interfaces";
import { InterstateOnChangeCallback } from "@interstate/components/InterstateEvents";

interface CreateNewDealProps {
  show: boolean;
  onHide: () => void;
}

const StyledButtonContainer = styled.div`
  bottom: 0;
  display: flex;
  gap: 12px;
  padding: 24px;
  position: absolute;
  right: 0;
  width: 100%;
`;

export const NewDealDrawer = ({ show, onHide }: CreateNewDealProps) => {
  const [hasCommonOrgId, setHasCommonOrgId] = useState(false);
  const [dealer, setDealer] = useState<AssociatedDealer | null>(null);
  const { products } = useSelector(getUserProfileState);

  const dealers = products["retail360"]?.dealers || [];

  const dealershipOptions = useMemo(
    () =>
      dealers.map(dealer => ({
        disabled: false,
        label: dealer.name,
        value: dealer.id
      })),
    [dealers]
  );

  const preselectedDealer = useMemo(
    () => (dealershipOptions.length === 1 ? dealers[0] : null),
    [dealershipOptions, dealers]
  );

  useEffect(() => {
    if (show) {
      setDealer(preselectedDealer);
      if (preselectedDealer) {
        setHasCommonOrgId(true);
      }
    } else {
      setDealer(null);
      setHasCommonOrgId(false);
    }
  }, [show, preselectedDealer]);

  const handleDealerChange: InterstateOnChangeCallback<
    SelectInputEventValue
  > = event => {
    const dealerId = event.target.value as string;
    const selectedDealer = products["retail360"]?.dealers.find(
      ({ id }) => id === dealerId
    );
    setDealer(selectedDealer ?? null);
  };

  const handeCloseSlide = () => {
    setDealer(null);
    setHasCommonOrgId(false);
    onHide();
  };

  return (
    <CcxmReusableBridgeBarFriendlySlidingDrawer
      header="Create New Deal"
      panelWidth="584px"
      position="right"
      show={show}
      topOffset={35}
      onHide={handeCloseSlide}
    >
      {!hasCommonOrgId && (
        <>
          <SelectInput
            disabled={dealershipOptions.length === 1}
            displayDeselectOption={false}
            label="Dealership"
            name="select-dealership"
            options={dealershipOptions}
            value={dealer?.id ?? undefined}
            onChange={handleDealerChange}
          />
          <StyledButtonContainer>
            <Button block buttonStyle="secondary" onClick={handeCloseSlide}>
              Cancel
            </Button>
            <Button
              block
              disabled={!dealer}
              onClick={() => setHasCommonOrgId(true)}
            >
              Continue
            </Button>
          </StyledButtonContainer>
        </>
      )}
      {hasCommonOrgId && dealer && (
        <LoadNewDealModule
          canDesk={dealer.deskingManager}
          commonOrgId={dealer.id}
          onCancel={handeCloseSlide}
          onCompleted={handeCloseSlide}
        />
      )}
    </CcxmReusableBridgeBarFriendlySlidingDrawer>
  );
};
