import styled from "styled-components";

const StyledGenerativeAIPromptCard = styled.div`
  padding: 16px;
  background-color: #ebf6ff;
  border: 1px solid #cacfd9;

  .generative-ai-prompt-card-user-display-name {
    font-style: italic;
  }
`;

interface GenerativeAIPromptCardProps {
  index: number;
  prompt: string;
  userDisplayName: string;
}

export function GenerativeAIPromptCard({
  index,
  prompt,
  userDisplayName
}: GenerativeAIPromptCardProps) {
  return (
    <StyledGenerativeAIPromptCard>
      <div
        className="generative-ai-prompt-card-user-display-name"
        data-testid={`generative-ai-prompt-card-user-display-name-${index}`}
        id={`generative-ai-prompt-card-user-display-name-${index}`}
      >
        {userDisplayName || "user"}
      </div>
      <div
        className="generative-ai-prompt-card-prompt"
        data-testid={`generative-ai-prompt-card-prompt-${index}`}
        id={`generative-ai-prompt-card-prompt-${index}`}
      >
        {prompt}
      </div>
    </StyledGenerativeAIPromptCard>
  );
}
