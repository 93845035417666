import {
  getErrorMessage,
  isResponseError,
  uploadAttachmentToScratchpad
} from "@coxauto-ui/communications-api";
import { SendCommunicationAttachmentResponse } from "@coxauto-ui/communications-interfaces";
import { LoadingIndicator } from "@coxauto-ui/core-components";
import { ChangeEvent, useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { ConversationContext, ConversationDetailsContext } from "../context";
import {
  replyAttachmentScratchpad,
  replyAttachmentScratchpadAlert,
  replyAttachmentScratchpadIcon,
  replyAttachmentScratchpadInput,
  replyAttachmentScratchpadLabel,
  replyAttachmentScratchpadThumbnail
} from "../testIds";
import MessageAttachmentThumbnail from "./message-attachment-thumbnail";
import { Alert } from "@interstate/components/Alert";
import { AlertSeverity } from "@interstate/components/Alert/Types/alertTypes";
import { toast } from "@interstate/components/Toast";
import { PaperClipIcon } from "@interstate/components/Icons";

const FILE_SIZE_LIMITS = {
  IMAGE: 2000000, // 2 MB
  VIDEO: 200000000, // 200 MB
  PDF: 600000 // 600 KB
};

export interface MessageAttachmentUploadProps {
  isDisabled: boolean;
}

const StagingArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const LabelAttachment = styled.label`
  display: flex;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
  flex-basis: 100%;

  svg {
    margin-right: 4px;
  }
`;

export function MessageAttachmentUpload(props: MessageAttachmentUploadProps) {
  const { env } = useContext(ConversationContext);
  const { setReplyText, messageAttachments, setMessageAttachments } =
    useContext(ConversationDetailsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentResponseType, setAttachmentResponseType] = useState<
    AlertSeverity | "info"
  >("info");
  const [attachmentResponseTitle, setAttachmentResponseTitle] = useState("");
  const [attachmentResponseMsg, setAttachmentResponseMsg] = useState("");
  const onChangeHandler = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        const contentType = file.type;
        if (validateFile(file)) {
          setIsLoading(true);
          const response = await uploadAttachmentToScratchpad(
            file,
            contentType,
            env
          );
          if (isResponseError(response)) {
            const errorMessage = getErrorMessage(response);
            if (response.statusCode === 400) {
              if (errorMessage && errorMessage.length > 0) {
                setAttachmentResponseMsg(errorMessage);
              }
            } else {
              toast.error(errorMessage);
            }
          } else {
            setAttachmentResponseMsg("");
            if (contentType.includes("video/")) {
              setReplyText(
                current =>
                  `${current} \n\n ${(response as string[]).join("\n")}`
              );
              const responseList = [
                {
                  key: response[0],
                  contentType,
                  fileName: file.name,
                  fileSize: file.size,
                  token: response[0]
                }
              ] as SendCommunicationAttachmentResponse[];
              setMessageAttachments(current => [...current, ...responseList]);
            } else {
              const responseList =
                response as SendCommunicationAttachmentResponse[];
              setMessageAttachments(current => [...current, ...responseList]);
            }
          }

          setIsLoading(false);
        }
        e.target.value = "";
      }
    },
    []
  );

  function validateFile(file: File) {
    const fileType = file.type;
    const fileSize = file.size;
    let validationResult = true;
    if (fileType.includes("image/")) {
      if (fileSize > FILE_SIZE_LIMITS.IMAGE) {
        setAttachmentResponseType("info");
        setAttachmentResponseTitle("File Too Large");
        setAttachmentResponseMsg(
          "The file is too large and cannot be uploaded. File limit is 2 MB"
        );
        validationResult = false;
      }
    } else if (fileType.includes("video/")) {
      if (fileSize > FILE_SIZE_LIMITS.VIDEO) {
        setAttachmentResponseType("info");
        setAttachmentResponseTitle("File Too Large");
        setAttachmentResponseMsg(
          "The file is too large and cannot be uploaded. File limit is 200 MB"
        );
        validationResult = false;
      }
    } else if (fileType.includes("application/pdf")) {
      if (fileSize > FILE_SIZE_LIMITS.PDF) {
        setAttachmentResponseType("info");
        setAttachmentResponseTitle("File Too Large");
        setAttachmentResponseMsg(
          "The file is too large and cannot be uploaded. File limit is 600 KB"
        );
        validationResult = false;
      }
    } else {
      setAttachmentResponseType("error");
      setAttachmentResponseTitle("File not supported");
      setAttachmentResponseMsg(
        "The file type is not accepted. The supported files are: jpeg, jpg, gif, png, mpeg, mp4, x-msvideo, x-flv, x-ms-wmv, quicktime, 3gpp, pdf, bmp, tiff"
      );
      validationResult = false;
    }
    return validationResult;
  }

  const onRemoveThumbHandler = useCallback((key: string, token: string) => {
    setMessageAttachments(current => [...current.filter(e => e.key !== key)]);
  }, []);

  return (
    <StagingArea data-testid={replyAttachmentScratchpad}>
      <input
        className="hidden"
        data-testid={replyAttachmentScratchpadInput}
        disabled={props.isDisabled}
        id="upload-attachment"
        type="file"
        onChange={onChangeHandler}
      />
      {messageAttachments.map(p => (
        <MessageAttachmentThumbnail
          attachmentMeta={p}
          data-testid={`${replyAttachmentScratchpadThumbnail}-${p.key}`}
          fileType={p.contentType}
          key={p.key}
          onRemoveThumbnail={onRemoveThumbHandler}
        />
      ))}
      {(isLoading && (
        <LoadingIndicator
          actionName="Loading upload communication attachment"
          htmlId="upload-attachment-loading-indicator"
        />
      )) || (
        <LabelAttachment
          data-testid={replyAttachmentScratchpadLabel}
          htmlFor="upload-attachment"
        >
          <PaperClipIcon data-testid={replyAttachmentScratchpadIcon} />
          Upload attachment - 5 MB total limit for all attachments.
        </LabelAttachment>
      )}
      {attachmentResponseMsg.length > 0 && (
        <Alert
          data-testid={replyAttachmentScratchpadAlert}
          htmlId="MessageReplyWarning"
          role={attachmentResponseType}
          title={attachmentResponseTitle}
          type={attachmentResponseType}
        >
          {attachmentResponseMsg}
        </Alert>
      )}
    </StagingArea>
  );
}

export default MessageAttachmentUpload;
