export enum generativeAIConversationItemType {
  prompt = "prompt",
  response = "response"
}

export interface GenerativeAIConversationItem {
  prompt: string;
  responseMessages: string[];
  type: generativeAIConversationItemType;
}
