import styled, { createGlobalStyle } from "styled-components";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { loadRemoteModule } from "@coxauto-ui/core/module-federation";
import { CcxmReusableBridgeBarFriendlySlidingDrawer } from "@coxauto-ui/ccxm/reusable/bridge-bar-friendly-sliding-drawer";
import "antd/dist/antd.css";
import React, {
  ComponentType,
  LazyExoticComponent,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { CcxmInsightsPillFilters } from "@coxauto-ui/ccxm-insights-filters-pill-filters";
import {
  FilterSequence,
  MatrixFilters,
  OptionFilters,
  Paging,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import { useInsightsListMode } from "@coxauto-ui/ccxm/util/use-insights-list-mode";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  configurationActions,
  getConfigurationState,
  getUserProfileState
} from "@coxauto-ui/ccxm/store";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm/util/derive-environment";
import { CcxmInsightsFiltersSearchFilter } from "@coxauto-ui/ccxm/insights/filters/search-filter";
import classNames from "classnames";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConversationProps } from "@coxauto-ui/communications-customer-conversation";

import { XMarkIcon } from "@interstate/components/Icons/XMarkIcon";
import { Button } from "@interstate/components/Button";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import { FunnelIcon } from "@interstate/components/Icons/FunnelIcon";
import {
  RoutingContext,
  useRouting,
  useRoutingLocation,
  useRoutingParams,
  useRoutingSearchParams
} from "@coxauto-ui/ccxm-routing";
import authClient from "@coxauto-ui/core/auth/get-auth-client-crw";
import { Badge } from "@interstate/components/Badge";
import CcxmInsightsListRealTime from "./ccxm-insights-list-real-time";
import { CustomerCardProps, DefaultTab } from "@coxauto-ui/customer-interfaces";
import { CcxmInsightsFiltersSlidingDrawerFilters } from "@coxauto-ui/ccxm/insights/filters/sliding-drawer-filters";
import { NewDealDrawer } from "./new-deal-drawer";

import { useCxmFlags } from "@coxauto-ui/core/third-party/launch-darkly";

const CustomerCardModule: LazyExoticComponent<
  ComponentType<CustomerCardProps>
> = React.lazy(() => loadRemoteModule("customer", "./CustomerCardModule"));

const CustomerConversationModule: LazyExoticComponent<
  ComponentType<ConversationProps>
> = React.lazy(() =>
  loadRemoteModule("communications", "./CustomerConversationModule")
);

const environment = deriveEnvironmentName();

export interface CcxmInsightsListProps {
  children: ReactNode;
  dealerGroupId: string;
  showRealTimeUpdates?: boolean;
  dealerGroupName: string;
  displayVinButton?: boolean;
  error?: string;
  filterSequence: FilterSequence[];
  loading: boolean;
  optionFilters: OptionFilters;
  pageTitle: string;
  paging: Paging;
  showFilters: boolean;
  textFilters: TextFilters;
  matrixFilters?: MatrixFilters;
  updateNotifications?: ReactNode;
  onFilterChange: (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters,
    matrixFiltersToApply?: MatrixFilters
  ) => void;
  onLoadMore: () => void;
}

const ToastOverrideStyle = createGlobalStyle`
.Toastify__toast-container {
    transform: translate(0, 28px) !important;
    width: 550px !important;

    @media (max-width: 768px) {
        transform: translate(0, 48px) !important;
        width: 370px !important;
    }
  }
  .Toastify__toast-theme--light {
    background-color: rgb(255, 255, 255);
    border-color: rgb(49, 138, 211);
    border-width: 1px 1px 1px 8px;
    border-style: solid;
    border-radius: 4px;
    color: rgb(0, 0, 0);
  }
`;

const StyledCcxmInsightsList = styled.div`
  height: 100%;
  #sliding-drawer-filters {
    min-width: 300px;
    max-width: 100vw;

    .cx-drawer__panel {
      overflow: auto;
      overscroll-behavior: none;
    }

    #pill-filters-wrapper {
      margin: 0;
      flex-wrap: wrap;
    }
  }

  .cx-drawer__background {
    z-index: 90;
  }

  #occ-inbox-sliding-drawer {
    #SlidingDrawerCloseBtn {
      z-index: 1;
    }

    #StyledCommonCommunicationsConversationListPageHeaderCustomerName {
      max-width: calc(100% - 38px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const StyledContentGrid = styled.div`
  display: grid;
  max-height: calc(100vh - 35px);
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  border-radius: 4px;
  background-color: #fff;
  height: 100%;

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    height: calc(-webkit-fill-available - 35px);
  }

  &.viewing-as {
    max-height: calc(100vh - 35px - 33px);

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      height: calc(-webkit-fill-available - 35px - 33px);
    }
  }

  &.desktopList,
  &.tabletList,
  &.tabletCustomer,
  &.mobileList,
  &.mobileCustomer {
    grid-template-areas:
      "header"
      "cards";
  }

  &.desktopCustomer {
    grid-template-columns: 500px 1fr;
    height: 100%;
    grid-template-areas:
      "header header"
      "cards customer";
  }
`;

const StyledHeader = styled.div`
  grid-area: header;
  border-top: 1px solid #b2b6bf;
  padding: 16px 24px;
  border-bottom: solid 4px #003468;
  margin-bottom: 16px;
  background: #fff;

  h1 {
    margin-bottom: 16px;
    font-size: 24px;

    .dealer-group-totalItems {
      color: #1890ff;
    }
  }

  .toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .filter-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
  }

  .create-new-deal-button {
    flex-shrink: 0;
    align-self: flex-start;
    max-width: 100%;
  }
`;

const StyledInsightCards = styled.div`
  position: relative;
  grid-area: cards;
  height: auto;
  overflow: auto;
  overscroll-behavior: none;
  margin: 0 24px;

  &.desktopCustomer {
    margin: 0 8px 8px 16px;
  }
`;

const StyledLockScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const StyledConsumerDetails = styled.div<{ spacingFromTop: number }>`
  &.desktopCustomer {
    grid-area: customer;
    margin: 0 16px 16px 8px;
    height: calc(100% - 32px);
    overflow: auto;
    overscroll-behavior: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 48px 1fr;
    grid-template-areas:
      "consumerdetailsheader"
      "customercardwrapper";
  }

  &.inline-occ-inbox {
    display: grid;
    grid-template-columns: 1fr 500px;
    grid-template-rows: 48px 1fr;
    grid-template-areas:
      "consumerdetailsheader consumerdetailsheader"
      "customercardwrapper customerconversationwrapper";
  }

  &.tabletCustomer,
  &.mobileCustomer {
    position: fixed;
    top: 35px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    padding: 16px;
    overflow-y: scroll;
    overflow-x: hidden;
    overscroll-behavior: none;
    z-index: 4;

    top: ${({ spacingFromTop }) =>
      spacingFromTop > 0 ? `${spacingFromTop}px` : 0};
    ${({ spacingFromTop }) =>
      spacingFromTop > 0 && "border-top: 1px solid #b2b6bf;"};
  }
`;

const StyledConsumerDetailsHeader = styled.div`
  grid-area: consumerdetailsheader;
  &.desktopCustomer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #0d2245;
    color: #fff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 48px;

    #customer-record-title {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 400;
    }

    .interstate-x-mark-icon {
      margin-right: 16px;
      cursor: pointer;
    }
  }

  &.tabletCustomer,
  &.mobileCustomer {
    display: flex;
    justify-content: space-between;
  }
`;

const StyledCustomerCardWrapper = styled.div`
  grid-area: customercardwrapper;

  #customer-card-container {
    height: 100%;
  }
`;

const StyledCustomerConversationWrapper = styled.div`
  grid-area: customerconversationwrapper;
  position: relative;
  border-right: 1px solid #e4e9ef;
  overflow: hidden;
`;

const StyledErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

const StyledPillWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

const StyledDivider = styled.hr`
  margin: 4px 0 16px;
`;

const StyledH1 = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export function CcxmInsightsList({
  children,
  dealerGroupId,
  dealerGroupName,
  displayVinButton = true,
  error,
  filterSequence,
  loading,
  matrixFilters,
  showRealTimeUpdates = false,
  optionFilters,
  pageTitle,
  paging,
  showFilters,
  textFilters,
  updateNotifications,
  onFilterChange,
  onLoadMore
}: CcxmInsightsListProps) {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navigate = useRouting();
  const { pathname } = useRoutingLocation();
  const { customerId } = useRoutingParams();
  const [searchParams] = useRoutingSearchParams();
  // eslint-disable-next-line @cspell/spellchecker
  const { "nx.cxm.show-addadeal-button": showNewDealButton } = useCxmFlags();

  const {
    isModule,
    selectedCustomerId,
    selectedDealId,
    selectedMultiDealCustomerId,
    defaultCustomerTab,
    setSelectedCustomerId,
    setSelectedDealId,
    setSelectedMultiDealCustomerId
  } = useContext(RoutingContext);
  const dispatch = useDispatch();
  const [spacingFromTop, setSpacingFromTop] = useState(
    window.scrollY > 35 ? 0 : 35 - window.scrollY
  );
  const [hasInlineOccInbox, setHasInlineOccInbox] = useState(
    window.innerWidth >= 1500
  );

  const { viewportMode } = useSelector(getConfigurationState, shallowEqual);
  const { viewAsUsername, bridgeUserName, bridgeUserId } = useSelector(
    getUserProfileState,
    shallowEqual
  );
  const [showNewDealDrawer, setShowNewDealDrawer] = useState(false);
  const [showSlidingDrawerFilters, setShowSlidingDrawerFilters] =
    useState(false);
  const listMode = useInsightsListMode();
  const [userDisplayName, setUserDisplayName] = useState<string | null>(null);

  const scrollCallback = () => {
    setSpacingFromTop(window.scrollY > 35 ? 0 : 35 - window.scrollY);
  };

  const resizeCallback = () => {
    const showInlineOccInbox = window.innerWidth >= 1500;
    if (hasInlineOccInbox !== showInlineOccInbox) {
      setHasInlineOccInbox(showInlineOccInbox);
    }
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage:
      paging.pageCount > paging.pageNumber || paging.pageNumber === 0,
    onLoadMore,
    rootMargin: "0px 0px 150px 0px"
  });

  useEffect(() => {
    if (!isModule) {
      const urlDealId = searchParams.get("dealId");
      const urlMultiDealCustomerId = searchParams.get("multiDealConsumerId");
      if (customerId && selectedCustomerId !== customerId) {
        setSelectedCustomerId(customerId);
      }
      if (urlDealId && selectedDealId !== urlDealId) {
        setSelectedDealId(urlDealId);
      }
      if (
        urlMultiDealCustomerId &&
        selectedMultiDealCustomerId !== urlMultiDealCustomerId
      ) {
        setSelectedMultiDealCustomerId(urlMultiDealCustomerId);
      }
    }
  }, [
    customerId,
    isModule,
    searchParams,
    selectedCustomerId,
    selectedDealId,
    selectedMultiDealCustomerId,
    setSelectedCustomerId,
    setSelectedDealId,
    setSelectedMultiDealCustomerId
  ]);

  useEffect(() => {
    window.addEventListener("resize", resizeCallback, true);
    return () => {
      window.removeEventListener("resize", resizeCallback, true);
    };
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollCallback, true);

    return () => {
      window.removeEventListener("scroll", scrollCallback, true);
    };
  });

  useEffect(() => {
    dispatch(configurationActions.setCustomerId(customerId || null));
  }, [customerId, dispatch]);

  useEffect(() => {
    if (loading === false && paging.pageNumber === 1) {
      scrollRef.current?.scrollTo({ top: 0, left: 0 });
    }
  }, [loading, paging.pageNumber]);

  useEffect(() => {
    (async () => {
      const claims = await authClient.getIdTokenClaims();
      setUserDisplayName(claims?.name || null);
    })();
  }, []);

  const toggleSlidingDrawerFilters = () => {
    setShowSlidingDrawerFilters(toggle => !toggle);
  };

  const toggleNewDealDrawer = () => {
    setShowNewDealDrawer(toggle => !toggle);
  };

  const handleFilterChange = (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters,
    matrixFiltersToApply?: MatrixFilters
  ) => {
    onFilterChange(
      optionFiltersToApply,
      textFiltersToApply,
      matrixFiltersToApply
    );
  };

  const customerDetailsCallback = useCallback(() => {
    setSelectedDealId(null);
    setSelectedCustomerId(null);
    searchParams.delete("dealId");
    const path =
      pathname.split(customerId || "")[0] + "?" + searchParams.toString();
    navigate(path, { replace: false });
  }, [
    customerId,
    navigate,
    pathname,
    searchParams,
    setSelectedCustomerId,
    setSelectedDealId
  ]);

  const user = useMemo(() => {
    return {
      key: bridgeUserId,
      name: bridgeUserName,
      custom: {
        dealerGroupId,
        dealerGroupName
      }
    };
  }, [bridgeUserId, bridgeUserName, dealerGroupId, dealerGroupName]);

  const occConfig = useMemo(() => {
    return {
      username: userDisplayName ?? "",
      disableReply: viewAsUsername !== null && viewAsUsername.trim() !== ""
    };
  }, [userDisplayName, viewAsUsername]);

  const onPageReload = () => {
    window.location.reload();
  };

  const setRefs = useCallback(
    (element: HTMLDivElement | null) => {
      scrollRef.current = element;
      rootRef(element);
    },
    [rootRef]
  );

  return (
    <StyledCcxmInsightsList>
      <ToastOverrideStyle />
      {dealerGroupId !== "" && (
        <>
          <StyledContentGrid
            className={classNames(listMode, {
              "viewing-as": viewAsUsername !== ""
            })}
            data-testid="ccxm-insights-list-content-grid"
          >
            <StyledHeader data-testid="dealer-group-header">
              <div>
                <StyledH1>
                  <span>{dealerGroupName}</span>{" "}
                  <Badge
                    data-count={paging.totalItems}
                    data-testid="insight-list-total-items-count"
                  >
                    Total {pageTitle} {paging.totalItems}
                  </Badge>
                  {showRealTimeUpdates && updateNotifications && (
                    <CcxmInsightsListRealTime>
                      {updateNotifications}
                    </CcxmInsightsListRealTime>
                  )}
                </StyledH1>
              </div>
              <div className="toolbar">
                <div className="filter-wrapper">
                  {showFilters && (
                    <Button
                      buttonStyle="secondary"
                      className="add-filter-pill"
                      data-testid="add-filter-button"
                      htmlId="inline-pill-filter-add-filter-dropdown"
                      size={"small"}
                      startIcon={<FunnelIcon />}
                      onClick={toggleSlidingDrawerFilters}
                    >
                      Filters
                    </Button>
                  )}
                  {showFilters && (
                    <CcxmInsightsFiltersSearchFilter
                      className="search-filter"
                      optionFilters={optionFilters}
                      textFilters={textFilters}
                      onChange={handleFilterChange}
                    />
                  )}
                  <CcxmInsightsPillFilters
                    clearButtonDisplay="separate"
                    filterSequence={filterSequence}
                    listMode={listMode}
                    loading={loading}
                    matrixFilters={matrixFilters}
                    optionFilters={optionFilters}
                    textFilters={textFilters}
                    onChange={handleFilterChange}
                  />
                </div>
                {showNewDealButton && (
                  <Button
                    className="create-new-deal-button"
                    size={"medium"}
                    onClick={toggleNewDealDrawer}
                  >
                    Create New Deal
                  </Button>
                )}
              </div>
            </StyledHeader>
            <StyledInsightCards
              data-testid="ccxm-insights-list-styled-insights-cards"
              ref={setRefs}
            >
              {error ? (
                <StyledErrorMessage>
                  <span data-testid="ccxm-insights-list-error-message">
                    Sorry, something went wrong. Please try again later.
                  </span>
                  <Button
                    data-testid="ccxm-insights-list-error-reload-button"
                    htmlId="ccxm-insights-list-error-reload-button"
                    onClick={onPageReload}
                  >
                    Reload
                  </Button>
                </StyledErrorMessage>
              ) : (
                <>
                  {children}
                  {showFilters && (
                    <div
                      data-testid="insights-list-load-more-ref"
                      ref={sentryRef}
                    />
                  )}
                  {loading === true && (
                    <div
                      style={{
                        backgroundColor: "#6d727a",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "60px"
                      }}
                    >
                      <LoadingIndicator
                        color="white"
                        htmlId="loadingIndicatorWhite"
                      />
                    </div>
                  )}
                </>
              )}
            </StyledInsightCards>
            {customerId && (
              <StyledConsumerDetails
                className={classNames([
                  hasInlineOccInbox
                    ? "inline-occ-inbox"
                    : "customer-card-occ-inbox",
                  listMode
                ])}
                data-testid="consumer-details-wrapper"
                role={listMode !== "desktopCustomer" ? "dialog" : undefined}
                spacingFromTop={spacingFromTop}
              >
                <StyledConsumerDetailsHeader className={listMode}>
                  <h1 id="customer-record-title">Customer Record</h1>
                  <div id="customer-record-modal-close-button">
                    <XMarkIcon
                      data-testid="customer-record-modal-close-button"
                      onClick={customerDetailsCallback}
                    />
                  </div>
                </StyledConsumerDetailsHeader>
                <Suspense fallback={<div>Loading Customer Card...</div>}>
                  <StyledCustomerCardWrapper>
                    <CustomerCardModule
                      commonConsumerID={customerId || ""}
                      dealId={selectedDealId || ""}
                      defaultTab={
                        (defaultCustomerTab as DefaultTab) || "information"
                      }
                      displayVinButton={displayVinButton}
                      doneCallback={customerDetailsCallback}
                      editCapability="enabled"
                      environment={environment}
                      getAuthOverride={authClient.getAuth}
                      ldUser={user}
                      occConfig={hasInlineOccInbox ? undefined : occConfig}
                      viewportMode={viewportMode}
                    />
                  </StyledCustomerCardWrapper>
                </Suspense>
                {hasInlineOccInbox && (
                  <Suspense
                    fallback={<div>Loading Customer Conversations...</div>}
                  >
                    <StyledCustomerConversationWrapper>
                      <CustomerConversationModule
                        customerId={customerId || ""}
                        getAuthOverride={authClient.getAuth}
                        occConfig={occConfig}
                        title="CUSTOMER INBOX"
                      />
                    </StyledCustomerConversationWrapper>
                  </Suspense>
                )}
              </StyledConsumerDetails>
            )}
          </StyledContentGrid>
          {(showSlidingDrawerFilters === true ||
            listMode === "tabletCustomer" ||
            listMode === "mobileCustomer") && <StyledLockScroll />}
          <CcxmReusableBridgeBarFriendlySlidingDrawer
            header={"Filters"}
            htmlId="sliding-drawer-filters"
            panelWidth="367px"
            show={showSlidingDrawerFilters}
            onHide={toggleSlidingDrawerFilters}
          >
            <StyledPillWrapper data-testid="pill-filters-wrapper">
              <CcxmInsightsPillFilters
                filterSequence={filterSequence}
                isInline={false}
                loading={loading}
                matrixFilters={matrixFilters}
                optionFilters={optionFilters}
                textFilters={textFilters}
                onChange={handleFilterChange}
              />
            </StyledPillWrapper>
            <CcxmInsightsFiltersSlidingDrawerFilters
              disabled={loading}
              filterSequence={filterSequence}
              matrixFilters={matrixFilters}
              optionFilters={optionFilters}
              textFilters={textFilters}
              onChange={handleFilterChange}
            />
            <StyledDivider id="checkbox-filter-divider" />
          </CcxmReusableBridgeBarFriendlySlidingDrawer>
          {showNewDealButton && (
            <NewDealDrawer
              show={showNewDealDrawer}
              onHide={toggleNewDealDrawer}
            />
          )}
        </>
      )}
    </StyledCcxmInsightsList>
  );
}

export default CcxmInsightsList;
