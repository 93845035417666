/* eslint-disable prettier/prettier */
import {
  ContactDetails,
  ConversationItem,
  ConversationTypeEnum,
  DealerCommunicationDetails,
  Environment,
  OccConfig,
  ReplyOption,
  SendCommunicationAttachmentResponse,
  SystemOfRecord
} from "@coxauto-ui/communications-interfaces";
import { Dispatch, SetStateAction, createContext } from "react";

export interface ICustomerConversationContext {
  activeListTab: ConversationTypeEnum;
  contactDetails: ContactDetails | null;
  customerId: string;
  customerName: string;
  customerSystem: SystemOfRecord;
  dealerCommunicationDetails: DealerCommunicationDetails | null;
  disableReply?: boolean;
  env: Environment;
  error: string;
  isGenerativeAIEnabled: boolean;
  occConfig: OccConfig;
  replyOptions: ReplyOption[] | [];
  showCustomerInfo?: boolean;
  title?: string;
  getUserName: () => string;
  setActiveListTab: Dispatch<SetStateAction<ConversationTypeEnum>>;
  setError: Dispatch<SetStateAction<string>>;
}

export const ConversationContext = createContext<ICustomerConversationContext>({
  activeListTab: ConversationTypeEnum.ALL,
  contactDetails: null,
  customerId: "",
  customerName: "",
  customerSystem: SystemOfRecord.COMMON,
  dealerCommunicationDetails: null,
  env: Environment.none,
  error: "",
  isGenerativeAIEnabled: false,
  occConfig: {} as OccConfig,
  replyOptions: [],
  showCustomerInfo: true,
  title: "",
  getUserName: () => "",
  setActiveListTab: () => undefined,
  setError: () => ""
});

export interface IConversationDetailsContext {
  conversationItems: ConversationItem[];
  currentConversationType: ConversationTypeEnum | null;
  displayConversationItems: ConversationItem[];
  latestConversationItemType: ConversationTypeEnum | null;
  messageAttachments: SendCommunicationAttachmentResponse[];
  messageCallContacted: string;
  messageCallType: string;
  recentContactEmail: string;
  recentContactText: string;
  replyText: string;
  replyTextSubject: string;
  selectedChannelValues: Map<ConversationTypeEnum, string> | null;
  selectedConversationItem: ConversationItem | null;
  selectedFromTextingPhoneNumber: string;
  selectedReplyOption: ReplyOption | null;
  xSignature: string;
  setConversationItems: Dispatch<SetStateAction<ConversationItem[]>>;
  setCurrentConversationType: Dispatch<
    SetStateAction<ConversationTypeEnum | null>
  >;
  setDisplayConversationItems: Dispatch<SetStateAction<ConversationItem[]>>;
  setLatestConversationItemType: Dispatch<
    SetStateAction<ConversationTypeEnum | null>
  >;
  setMessageAttachments: Dispatch<
    SetStateAction<SendCommunicationAttachmentResponse[]>
  >;
  setMessageCallContacted: Dispatch<SetStateAction<string>>;
  setMessageCallType: Dispatch<SetStateAction<string>>;
  setRecentContactEmail: Dispatch<SetStateAction<string>>;
  setRecentContactText: Dispatch<SetStateAction<string>>;
  setReplyText: Dispatch<SetStateAction<string>>;
  setReplyTextSubject: Dispatch<SetStateAction<string>>;
  setSelectedChannelValues: Dispatch<
    SetStateAction<Map<ConversationTypeEnum, string> | null>
  >;
  setSelectedConversationItem: Dispatch<
    SetStateAction<ConversationItem | null>
  >;
  setSelectedFromTextingPhoneNumber: Dispatch<SetStateAction<string>>;
  setSelectedReplyOption: Dispatch<SetStateAction<ReplyOption | null>>;
  setXSignature: Dispatch<SetStateAction<string>>;
}

export const ConversationDetailsContext = createContext(
  {} as IConversationDetailsContext
);
