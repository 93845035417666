import { formatDistanceToNow } from "date-fns";
import styled from "styled-components";
import { memo } from "react";
export interface DateDistanceToNowProps {
  date: string;
}

const StyledDateDistanceToNow = styled.span`
  cursor: default;
`;

const DateDistanceToNow = memo(({ date }: DateDistanceToNowProps) => {
  const currentDate = new Date(date);
  const now = new Date();

  const isFuture = currentDate > now;

  const distanceToNow = formatDistanceToNow(currentDate);
  const formattedDate = currentDate.toLocaleString();
  const prefixOrSuffix = isFuture
    ? `in ${distanceToNow}`
    : `${distanceToNow} ago`;

  return (
    <StyledDateDistanceToNow
      className="date-distance-to-now-text"
      data-testid="date-distance-to-now-text"
      title={formattedDate}
    >
      {prefixOrSuffix}
    </StyledDateDistanceToNow>
  );
});
export default DateDistanceToNow;
