import { getBaseUrl, getHeader } from "../config";
import { PostCrmGenAIContentStreamRequest } from "./interfaces/post-crm-genai-content-stream";
import { fetchEventSource } from "@microsoft/fetch-event-source";

export async function postCrmGenAIContentStream({
  payload,
  env,
  onclose,
  onerror,
  onmessage
}: PostCrmGenAIContentStreamRequest) {
  const url = `${getBaseUrl(env)}cxm/crm-genai/content-stream`;
  const headers = await getHeader();

  // if we experience infinite retries or the browser runs out of memory waiting for a prompt, setup an AbortController and pass it to the `signal` arg
  await fetchEventSource(url, {
    method: "POST",
    headers,
    body: JSON.stringify(payload),
    onmessage,
    onerror,
    onclose
  });
}
