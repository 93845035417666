import {
  MatrixFilters,
  OptionFilterComponentType,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import {
  CcxmReusableCollapsibleCheckboxList,
  CcxmReusableDropdownSelect
} from "@coxauto-ui/ccxm/reusable/collapsible-checkbox-list";
import { useCallback } from "react";
import styled from "styled-components";

/* eslint-disable-next-line */
export interface CcxmInsightsCheckboxFilterProps {
  disabled: boolean;
  optionFilters: OptionFilters;
  textFilters: TextFilters;
  matrixFilters?: MatrixFilters;
  categoryType: string;
  onChange: (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters,
    matrixFiltersToApply?: MatrixFilters
  ) => void;
}

const StyledFiltersWrapper = styled.div`
  .form-group {
    text-align: left;

    > .control-label {
      font-weight: bold;
    }

    .checkbox label {
      flex-wrap: nowrap;
    }

    .cx-checkbox input[type="checkbox"] {
      flex-shrink: 0;
    }
  }
`;

export function CcxmInsightsCheckboxFilter({
  disabled,
  optionFilters,
  textFilters,
  matrixFilters,
  categoryType,
  onChange
}: CcxmInsightsCheckboxFilterProps) {
  const { displayName, options, selectedValues, componentType } =
    optionFilters[categoryType];
  const onFilterSelect = useCallback(
    (e: { target: { name: string; value: string[] } }) => {
      const optionFiltersToApply = {
        ...optionFilters,
        [e.target.name]: {
          ...optionFilters[e.target.name],
          selectedValues: e.target.value
        }
      };

      onChange(optionFiltersToApply, textFilters, matrixFilters);
    },
    [optionFilters, textFilters, onChange, matrixFilters]
  );

  const onDropdownSelect = useCallback(
    (e: { target: { name: string; value: string | null } }) => {
      const optionFiltersToApply = {
        ...optionFilters,
        [e.target.name]: {
          ...optionFilters[e.target.name],
          selectedValues: !e.target.value ? [] : [e.target.value]
        }
      };

      onChange(optionFiltersToApply, textFilters, matrixFilters);
    },
    [optionFilters, textFilters, onChange, matrixFilters]
  );

  const getCheckboxFilter = () => (
    <CcxmReusableCollapsibleCheckboxList
      data-selectedoptions={JSON.stringify(selectedValues)}
      expandedByDefault={false}
      htmlId={`${categoryType.toLowerCase()}-checkbox-list`}
      key={`${categoryType}-checkbox-list`}
      label={displayName}
      name={categoryType}
      options={options.map(x => ({
        disabled,
        value: x.value,
        label:
          categoryType === "vehicleModel" ||
          categoryType === "vehicleMake" ||
          categoryType === "dealLifecycleStatus" ||
          categoryType === "userId" ||
          categoryType === "isAccepted"
            ? `${x.label} (${x.count})`
            : x.label
      }))}
      values={selectedValues}
      onChange={onFilterSelect}
    />
  );

  const getDropdownFilter = () => (
    <CcxmReusableDropdownSelect
      data-selectedoptions={JSON.stringify(selectedValues)}
      disabled={disabled}
      htmlId={`${categoryType.toLowerCase()}-dropdown-single-select-list`}
      key={`${categoryType}-dropdown-single-select-list`}
      label={displayName}
      name={categoryType}
      options={options}
      placeholder="Any"
      size="small"
      value={selectedValues.length === 0 ? "" : selectedValues[0]}
      onChange={onDropdownSelect}
    />
  );

  const getFilterComponent = () => {
    return componentType === OptionFilterComponentType.optionList
      ? getCheckboxFilter()
      : componentType === OptionFilterComponentType.optionSingleSelect
      ? getDropdownFilter()
      : null;
  };

  return (
    <StyledFiltersWrapper
      data-testid={`ccxm-insights-filters-checkbox-filter-${categoryType}`}
    >
      {getFilterComponent()}
    </StyledFiltersWrapper>
  );
}

export default CcxmInsightsCheckboxFilter;
